.c-map-background-switch {
  position: absolute;
  right: 1.5rem;
  top: 128px;
  z-index: 10;
  display: flex;
}

.c-map-background-switch__button {
  width: 80px;
  background-color: #ffffff;
  padding: 0.5rem;
  color: #070130;
  outline: 1px solid currentColor;
}

.c-map-background-switch__button:first-child {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.c-map-background-switch__button:last-child {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.c-map-background-switch__button:hover {
  background-color: #eeeeee;
}

.c-map-background-switch__button--active {
  background-color: #ebf4ff;
  color: #106ad7;
}

.c-map-background-switch__button--active:hover {
  background-color: #d4e5fa;
  color: #0055bb;
}
