.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 36px;
  padding: 10px;
  border: 1px solid;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 33px;
  width: 100%;
  min-width: 160px;
  margin-left: 1px;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  z-index: 2;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: blue;
}

.c-question-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.c-question-modal {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.accordion-button[aria-expanded='true'] .arrow-button {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

/* cyrillic-ext */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/minerva/_next/static/media/78187650dd6b50b3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/minerva/_next/static/media/4529092560591ab4-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/minerva/_next/static/media/b8222d26e20b2e06-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/minerva/_next/static/media/008f2e8b4aae291f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/minerva/_next/static/media/3c46462b57ac880e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/minerva/_next/static/media/3534416bbfdcc9be-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/minerva/_next/static/media/78187650dd6b50b3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/minerva/_next/static/media/4529092560591ab4-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/minerva/_next/static/media/b8222d26e20b2e06-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/minerva/_next/static/media/008f2e8b4aae291f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/minerva/_next/static/media/3c46462b57ac880e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Manrope_1de729';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/minerva/_next/static/media/3534416bbfdcc9be-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Manrope_Fallback_1de729';src: local("Arial");ascent-override: 102.96%;descent-override: 28.98%;line-gap-override: 0.00%;size-adjust: 103.53%
}.__className_1de729 {font-family: '__Manrope_1de729', '__Manrope_Fallback_1de729';font-style: normal
}.__variable_1de729 {--font-manrope: '__Manrope_1de729', '__Manrope_Fallback_1de729'
}

.anchor-tag a {--tw-text-opacity: 1;color: rgb(0 72 255 / var(--tw-text-opacity))
}.anchor-tag a:hover {text-decoration-line: underline
}

:root,
:host {
  --ol-background-color: white;
  --ol-accent-background-color: #F5F5F5;
  --ol-subtle-background-color: rgba(128, 128, 128, 0.25);
  --ol-partial-background-color: rgba(255, 255, 255, 0.75);
  --ol-foreground-color: #333333;
  --ol-subtle-foreground-color: #666666;
  --ol-brand-color: #00AAFF;
}

.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 1.5px solid var(--ol-background-color);
  background-color: var(--ol-partial-background-color);
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute;
}

.ol-scale-line {
  background: var(--ol-partial-background-color);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}

.ol-scale-line-inner {
  border: 1px solid var(--ol-subtle-foreground-color);
  border-top: none;
  color: var(--ol-foreground-color);
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
  transition: all 0.25s;
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-bar-inner {
  display: flex;
}

.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  background-color: var(--ol-foreground-color);
  float: right;
  z-index: 10;
}

.ol-scale-step-text {
  position: absolute;
  bottom: -5px;
  font-size: 10px;
  z-index: 11;
  color: var(--ol-foreground-color);
  text-shadow: -1.5px 0 var(--ol-partial-background-color), 0 1.5px var(--ol-partial-background-color), 1.5px 0 var(--ol-partial-background-color), 0 -1.5px var(--ol-partial-background-color);
}

.ol-scale-text {
  position: absolute;
  font-size: 12px;
  text-align: center;
  bottom: 25px;
  color: var(--ol-foreground-color);
  text-shadow: -1.5px 0 var(--ol-partial-background-color), 0 1.5px var(--ol-partial-background-color), 1.5px 0 var(--ol-partial-background-color), 0 -1.5px var(--ol-partial-background-color);
}

.ol-scale-singlebar {
  position: relative;
  height: 10px;
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid var(--ol-foreground-color);
}

.ol-scale-singlebar-even {
  background-color: var(--ol-subtle-foreground-color);
}

.ol-scale-singlebar-odd {
  background-color: var(--ol-background-color);
}

.ol-unsupported {
  display: none;
}

.ol-viewport,
.ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.ol-viewport canvas {
  all: unset;
  overflow: hidden;
}

.ol-viewport {
  touch-action: pan-x pan-y;
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

.ol-grabbing {
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: grab;
}

.ol-control {
  position: absolute;
  background-color: var(--ol-subtle-background-color);
  border-radius: 4px;
}

.ol-zoom {
  top: .5em;
  left: .5em;
}

.ol-rotate {
  top: .5em;
  right: .5em;
  transition: opacity .25s linear, visibility 0s linear;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}

.ol-full-screen {
  right: .5em;
  top: .5em;
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: var(--ol-subtle-foreground-color);
  font-weight: bold;
  text-decoration: none;
  font-size: inherit;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: .4em;
  background-color: var(--ol-background-color);
  border: none;
  border-radius: 2px;
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  display: block;
  font-weight: normal;
  will-change: transform;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-control button:hover,
.ol-control button:focus {
  text-decoration: none;
  outline: 1px solid var(--ol-subtle-foreground-color);
  color: var(--ol-foreground-color);
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution {
  text-align: right;
  bottom: .5em;
  right: .5em;
  max-width: calc(100% - 1.3em);
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
}

.ol-attribution a {
  color: var(--ol-subtle-foreground-color);
  text-decoration: none;
}

.ol-attribution ul {
  margin: 0;
  padding: 1px .5em;
  color: var(--ol-foreground-color);
  text-shadow: 0 0 2px var(--ol-background-color);
  font-size: 12px;
}

.ol-attribution li {
  display: inline;
  list-style: none;
}

.ol-attribution li:not(:last-child):after {
  content: " ";
}

.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}

.ol-attribution button {
  flex-shrink: 0;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution:not(.ol-collapsed) {
  background: var(--ol-partial-background-color);
}

.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
}

.ol-attribution.ol-uncollapsible img {
  margin-top: -.2em;
  max-height: 1.6em;
}

.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  top: 4.5em;
  left: .5em;
  height: 200px;
}

.ol-zoomslider button {
  position: relative;
  height: 10px;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  left: 0.5em;
  bottom: 0.5em;
}

.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0;
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: block;
}

.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid var(--ol-subtle-foreground-color);
  height: 150px;
  width: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 0;
  left: 0;
  position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: var(--ol-subtle-background-color);
}

.ol-overviewmap-box {
  border: 1.5px dotted var(--ol-subtle-foreground-color);
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

.ol-overviewmap .ol-viewport:hover {
  cursor: pointer;
}

.map-loader {
  position: absolute;
  left: 120px;
  top: 128px;
}

.map-loader.move-right {
  left: 550px;
}

.c-map-background-switch {
  position: absolute;
  right: 1.5rem;
  top: 128px;
  z-index: 10;
  display: flex;
}

.c-map-background-switch__button {
  width: 80px;
  background-color: #ffffff;
  padding: 0.5rem;
  color: #070130;
  outline: 1px solid currentColor;
}

.c-map-background-switch__button:first-child {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.c-map-background-switch__button:last-child {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.c-map-background-switch__button:hover {
  background-color: #eeeeee;
}

.c-map-background-switch__button--active {
  background-color: #ebf4ff;
  color: #106ad7;
}

.c-map-background-switch__button--active:hover {
  background-color: #d4e5fa;
  color: #0055bb;
}

.c-layer-image-object-factory-loader {
  width: 100%;
  height: 100%;
  margin-left: -24px;
  margin-top: -24px;
  background: #f9f9f980;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-select__control {
  height: 40px;
}

.react-select__menu {
  margin: 0 !important;
}

.c-layer-text-factory-modal-loader {
  width: 100%;
  height: 100%;
  margin-left: -24px;
  margin-top: -24px;
  background: #f9f9f980;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-color-picker {
  position: absolute;
  z-index: 1;
  display: block;
  width: -moz-max-content;
  width: max-content;
  min-width: 258px;
  visibility: visible;
}
.rc-color-picker-hidden {
  display: none;
}
.rc-color-picker-panel {
  display: flex;
  flex-direction: column;
  width: 258px;
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.rc-color-picker-panel-disabled {
  cursor: not-allowed;
}
.rc-color-picker-select {
  margin-bottom: 12px;
}
.rc-color-picker-select .rc-color-picker-palette {
  min-height: 160px;
  overflow: hidden;
  border-radius: 4px;
}
.rc-color-picker-select .rc-color-picker-palette > .rc-color-picker-gradient {
  border-top-left-radius: 5px;
}
.rc-color-picker-saturation {
  position: absolute;
  border-radius: inherit;
  inset: 0px;
}
.rc-color-picker-handler {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.06);
}
.rc-color-picker-handler-sm {
  width: 12px;
  height: 12px;
}
.rc-color-picker-slider {
  width: 100%;
  margin-bottom: 12px;
}
.rc-color-picker-slider .rc-color-picker-palette {
  height: 8px;
}
.rc-color-picker-slider .rc-color-picker-gradient {
  border-radius: 4px;
}
.rc-color-picker-slider-alpha {
  background: url(/minerva/_next/static/media/alpha.f9e596b5.svg);
}
.rc-color-picker-color-block {
  position: relative;
  width: 28px;
  height: 28px;
  margin-left: 8px;
  overflow: hidden;
  background-image: url(/minerva/_next/static/media/alpha.f9e596b5.svg);
  border-radius: 4px;
}
.rc-color-picker-color-block-inner {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.rc-color-picker-slider-container {
  display: flex;
}
.rc-color-picker-slider-container .rc-color-picker-slider-group {
  flex: 1;
}
.rc-color-picker-slider-container .rc-color-picker-slider-group-disabled-alpha {
  display: flex;
  align-items: center;
}
.rc-color-picker-slider-container .rc-color-picker-slider-group-disabled-alpha .rc-color-picker-slider {
  margin-bottom: 0;
}

.c-layer-rect-factory-modal-loader {
  width: 100%;
  height: 100%;
  margin-left: -24px;
  margin-top: -24px;
  background: #f9f9f980;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-layer-oval-factory-modal-loader {
  width: 100%;
  height: 100%;
  margin-left: -24px;
  margin-top: -24px;
  background: #f9f9f980;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-layer-line-factory-modal-loader {
  width: 100%;
  height: 100%;
  margin-left: -24px;
  margin-top: -24px;
  background: #f9f9f980;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-element-glyph-modal-loader {
  width: 100%;
  height: 100%;
  margin-left: -24px;
  margin-top: -24px;
  background: #f9f9f980;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-layer-factory-loader {
  width: 100%;
  height: 100%;
  margin-left: -24px;
  margin-top: -24px;
  background: #f9f9f980;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

