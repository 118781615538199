.c-layer-text-factory-modal-loader {
  width: 100%;
  height: 100%;
  margin-left: -24px;
  margin-top: -24px;
  background: #f9f9f980;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
